@import '../../styles/main.scss';

.EventsBlock {
  clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
  background-image: url('./background.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  margin-top: -50px;
  padding: 50px 0;

  @media(max-width: $desktop-width) {
    clip-path: none;
    margin: 0;
    padding: 24px 0;
  }

  .Title {
    @include pt30;
    text-transform: uppercase;
    color: $main-color;
    padding-top: 32px;
    text-align: center;

    @media(max-width: $desktop-width) {
      padding-top: 0;
    }

    @media(max-width: $tablet-width) {
      @include pt20;
    }

    @media(max-width: $mobile-width) {
      @include pt14;
    }
  }
  
  .EventsWrapper {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1200px;
    margin: 50px auto 0;
    place-content: space-between;

    @media(max-width: $mobile-width) {
      margin: 16px auto 0;
    }

    .EventCard {
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      width: 100%;
      max-width: 370px;

      &:not(:first-child) {
        margin-left: 50px;
      }

      .EventImage {
        img {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        // @media(max-width: $mobile-width) {
        //   display: none;
        // }
      }

      .MobileImage {
        height: 150px;
        width: auto;
        background-repeat: no-repeat;
        background-position: center;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        display: none;

        @media(max-width: $mobile-width) {
          display: block;
        }
      }

      .EventDescription {
        flex: 1;
        position: relative;
        padding: 20px;
        // background: rgba(255, 255, 255, 0.4);
        border: 1px solid #B5B5B5;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        @include pt15;

        @media(max-width: $mobile-width) {
          @include pt9;
        }

        &::before {
          content: '';
          // margin: -35px;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          filter: blur(10px);
          z-index: -1;
          background: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  .PaginationWrapper {
    display: flex;
    flex-direction: row;
    place-content: center;
    margin-top: 50px;

    @media(max-width: $mobile-width) {
      margin-top: 20px;
    }

    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        button {
          border-radius: 5px;
          border: 1px solid #4A4A4A;
          background-color: #4A4A4A;
          color: $text-white;
        }
        &.ant-pagination-disabled {
          button {
            opacity: 0.6;
          }
        }
      }
      .ant-pagination-item {
        background-color: transparent;
        border: unset;
        border-radius: 5px;
        color: #4A4A4A;
        @include pt15;

        &:hover {
          a {
            color: $main-color;
          }
        }
        &:focus {
          a {
            color: $main-color;
          }
        }

        &.ant-pagination-item-active {
          background-color: $main-color;
          color: $text-white;

          a {
            color: $text-white;
          }
        }
      }
      .ant-pagination-jump-next, .ant-pagination-jump-prev {
        .ant-pagination-item-container {
          .ant-pagination-item-link-icon {
            color: $main-color;
          }
        }
      }
    }
  }
}