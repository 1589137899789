@import '../../styles/main.scss';
@import 'react-big-calendar/lib/sass/styles';

.CalendarBlock {
  clip-path: polygon(0 35px, 100% 0, 100% 95%, 0% 100%);
  min-height: 1000px;
  background-image: url('./new_back.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  margin-top: -36px;
  padding-top: 36px;

  @media(max-width: $desktop-width) {
    clip-path: none;
    margin: 0;
    padding: 24px 0;
    min-height: auto;
  }

  @media(max-width: $mobile-width) {
    display: none;
  }

  &.mobile {
    display: block;
    padding: 0;
  }

  .Title {
    @include pt30;
    text-transform: uppercase;
    color: $text-white;
    padding-top: 32px;
    text-align: center;

    @media(max-width: $desktop-width) {
      padding-top: 0;
    }

    @media(max-width: $tablet-width) {
      @include pt20;
    }

    @media(max-width: $mobile-width) {
      display: none;
    }
  }

  .CalendarWrapper {
    padding: 20px 50px;
    display: flex;
    flex-direction: row;

    max-width: 1200px;
    margin: 0 auto;

    @media(max-width: $mobile-width) {
      padding: 0;
    }

    .rbc-calendar {
      flex: 1;

      @media(max-width: $mobile-width) {
        align-items: center;
      }

      .rbc-month-view {
        border: none;

        .rbc-month-header {
          margin-bottom: 40px;
          
          .rbc-header {
            padding: 12px 0;
            border-bottom: none;
            background-color: $main-color;
  
            &:first-of-type {
              border-top-left-radius: 6px;
            }
            &:last-of-type {
              border-top-right-radius: 6px;
            }
  
            span {
              @include pt20;
              color: $text-white;
              text-transform: uppercase;

              @media(max-width: $tablet-width) {
                @include pt15;
              }

              @media(max-width: $mobile-width) {
                @include pt9;
              }
            }
          }
        }
        
        .rbc-month-row {
          .rbc-row-bg {
            .rbc-day-bg {
              background-color: $calendar-white;

              &.rbc-off-range-bg {
                background-color: $calendar-gray;
              }
              
              &:not(:last-of-type) {
                margin-right: 3px;
              }

              +.rbc-day-bg {
                border-left: none;
                margin-left: 3px;
              }
            }
          }

          .rbc-row-content {
            .rbc-row {
              .rbc-date-cell {
                padding-right: 0;
                text-align: left;
                padding-left: 5px;

                &:not(:last-of-type) {
                  margin-right: 3px;
                }

                +.rbc-date-cell {
                  margin-left: 3px;
                }

                a {
                  @include pt20;
                  color: $text-calendar-white;

                  @media (max-width: $tablet-width) {
                    @include pt15;
                  }
                }

                &.rbc-off-range {
                  a {
                    color: $text-calendar-gray;
                  }
                }
              }

              .rbc-row-segment {
                padding: 0 0 3px 3px;

                .rbc-event {
                  padding: 2px 0;
                  border-radius: 0;

                  &:focus {
                    outline: none;
                    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.35));
                  }
  
                  .rbc-event-content {
                    @include pt10;
                    font-weight: 600;
                    text-align: center;
                    color: $text-black;
                  }
                }
              }
            }
          }

          +.rbc-month-row {
            border-top: none;
            margin-top: 6px;
          }
        }
      }
    }
  }

  .EventWrapper {
    display: flex;
    flex-direction: column;
    width: 320px;
    margin-left: 45px;
    margin-top: 85px;

    .EventTitle {
      padding: 12px;
      text-align: center;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background-color: $main-color;
      @include pt20;
      color: $text-white;
    }

    .EventInfo {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      background-color: rgba(255,255,255, 0.9);

      .EventTeacher {
        display: flex;
        flex-direction: column;

        .TeacherCardRow {
          display: flex;
          flex-direction: row;
          padding: 20px;

          .TeacherInfo {
            margin-left: 20px;

            .TeacherName {
              @include pt20;
            }

            .TeacherDescription {
              @include pt9;
              margin-top: 5px;
            }

            .TeacherWork {
              margin-top: 10px;
              @include pt9;
            }

            .TeacherWorkProgress {
              @include pt9;
            }

            .Responsibility {
              @include pt12;
              margin-top: 16px;
            }

            .Responsibilities {
              @include pt9;
              margin-top: 6px;
            }
          }
        }

        .Divider {
          margin: 0;
          color: #9E9E9E;
        }

        .EventTime {
          padding: 20px 30px;

          .BigInfo {
            @include pt20;
          }

          .SmallInfo {
            @include pt15;
            @include medium;
          }
        }
      }
    }

    .TeacherPhone {
      margin-top: 10px;
      padding: 12px 0 10px;
      background-color: rgba(255,255,255, 0.9);
      display: flex;
      flex-direction: column;
      align-items: center;

      .PhoneInfo {
        @include pt12;
      }

      a {
        @include phoneLink;
        color: $text-black;
        text-decoration: underline;
      }
    }
  }
}

.Toolbar {
  display: flex;
  flex-direction: row;
  width: 250px;
  padding: 12px 0;
  border-radius: 6px;
  background-color: $main-color;
  color: $text-white;
  justify-content: space-around;
  align-items: center;

  margin-bottom: 30px;

  .ToolbarArrow {
    font-size: 26px;
    cursor: pointer;

    @media(max-width: $mobile-width) {
      font-size: 14px;
    }
  }

  .Label {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include pt15;
    text-transform: capitalize;

    span {
      @include pt20;
      margin-left: 8px;
    }

    @media(max-width: $tablet-width) {
      @include pt12;

      span {
        @include pt15;
      }
    }

    @media(max-width: $mobile-width) {
      font-size: 10px;

      span {
        @include pt12;
      }
    }
  }
}

.CalendarModal {

  .ant-modal-content {
    background-color: $main-backgrond;
    color: #000;

    .ant-modal-close {
      color: #fff;

      .ant-modal-close-x {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 22px;
      }
    }

    .ant-modal-body {
      padding: 44px 24px;

      @media(max-width: $mobile-width) {
        padding: 44px 8px;
      }
    }

    .ModalWrapper {
      display: flex;
      flex-direction: column;

      .ModalTitle {
        display: flex;
        flex-direction: row;
        place-content: center;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-color: $main-color;
        color: $text-white;
        padding: 8px 0;
        @include pt20;
      }
      .ModalInfo {
        margin-top: 8px;
        padding: 10px;
        background-color: $text-white;

        .ModalTeacher {
          display: flex;
          flex-direction: column;

          .TeacherCardRow {
            display: flex;
            flex-direction: row;

            .TeacherInfo {
              margin-left: 24px;

              .TeacherName {
                @include pt25;
              }

              .TeacherDescription {
                @include pt10;
              }

              .TeacherWork {
                @include pt9;
              }
            }
          }

          .TeacherDesc {
            display: flex;
            flex-direction: row;
            place-content: space-between;
            margin-top: 32px;

            .TeacherDescItem {
              display: flex;
              flex-direction: column;

              &:last-of-type {
                margin-left: 12px;
                border-left: 1px solid #000;
                padding-left: 12px;
              }

              .Responsibility {
                @include pt12;
              }
              
              .Responsibilities {
                @include pt9;
              }

              .DateInfo {
                @include bold;
                font-size: 14px;
              }

              .RoomInfo {
                @include medium;
                font-size: 13px;
              }

              .TimeInfo {
                @include medium;
                font-size: 15px;
              }
            }
          }
        }
      }

      .TeacherPhone {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8px;
        background-color: $text-white;

        .PhoneInfo {
          @include pt12;
        }

        .PhoneCall {
          @include phoneLink;
          color: $text-black;
          text-decoration: underline;
        }
      }
    }
  }
}
