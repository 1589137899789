@import '../../styles/main.scss';

.Intro {
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 715px, 0% 100%);

  @media(max-width: $desktop-width) {
    clip-path: none;
  }

  .awssld__wrapper {
    max-height: 750px;
  }

  .awssld__bullets {
    position: absolute;
    bottom: 100px;
    z-index: 5;

    @media(max-width: $tablet-width) {
      bottom: 16px;
    }

    button {
      width: 14px;
      height: 14px;
      margin: 5px 10px;
      background: $text-white;

      &.awssld__bullets--active {
        transform: scale(1.2);
        background: $main-color;
      }

      @media(max-width: $mobile-width) {
        width: 8px;
        height: 8px;

        margin: 2px 6px;
      }
    }
  }

  .IntroTextBlock {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 100%;
    color: $text-white;
    padding: 0 5em;

    @media(max-width: $mobile-width) {
      padding: 0 3em;
    }

    .TextBlockTitle {
      @include pt50;
      text-transform: uppercase;

      @media(max-width: $tablet-width) {
        @include pt30;
      }

      @media(max-width: $mobile-width) {
        @include pt12;
        @include bold;
      }
    }

    .TextBlockButton {
      @include pt15;
      padding: 12px 24px;
      background: $main-color;
      border: 1px solid $main-color;
      cursor: pointer;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

      &:hover {
        border: 1px solid $text-white;
      }

      @media(max-width: $mobile-width) {
        padding: 6px 12px;
        font-size: 8px;
      }

      display: block;

      @media(max-width: $mobile-width) {
        display: none;
      }

      &.mobile {
        display: none;

        @media(max-width: $mobile-width) {
          display: block;
        }
      }
    }

    .MobileButtonWrap {
      display: none;

      @media(max-width: $mobile-width) {
        display: flex;
        flex-direction: column;
      }
    }

    .Calendar {
      background: $main-color;
      border: 1px solid $main-color;
      cursor: pointer;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      padding: 6px 12px;
      font-size: 8px;
      @include regular;
      margin-top: 16px;

      display: none;

      @media(max-width: $mobile-width) {
        display: block;
      }
    }
  }

  .excursionIcon {
    position: absolute;
    bottom: 22%;
    right: 10%;
    z-index: 2;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    @media(max-width: $tablet-width) {
      max-width: 130px;
    }

    @media(max-width: $mobile-width) {
      max-width: 90px;
    }
  }
}