@import '../../styles/main.scss';

.ResultsBlock {
  padding: 50px 0;
  background-image: url('./background.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  background-color: $main-color;

  @media(max-width: $desktop-width) {
    padding: 24px 0;
  }

  .Title {
    @include pt30;
    text-transform: uppercase;
    color: $text-white;
    
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media(max-width: $desktop-width) {
      padding-top: 0;
    }

    @media(max-width: $tablet-width) {
      @include pt20;
    }

    @media(max-width: $mobile-width) {
      @include pt14;
    }
  }

  .ResultRows {
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
    width: 70%;
    max-width: 1000px;

    @media(max-width: $mobile-width) {
      margin: 16px auto 0;
      width: 90%;
    }

    .ResultCard {
      display: flex;
      flex-direction: row;

      &:not(:first-child) {
        margin-top: 40px;
      }

      .ResultImage {
        img {
          width: auto;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &.mobile {
          img {
            width: 100%;
          }
        }
      }
      
      .ResultDescription {
        flex: 1;
        position: relative;
        padding: 16px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        background: rgba(185, 47, 97, 0.5);
        @include pt15;
        color: $text-white;

        display: flex;
        flex-direction: column;
        place-content: space-between;
        
        &::before {
          content: '';
          // margin: -35px;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          filter: blur(10px);
          z-index: -1;
          background: rgba(185, 47, 97, 0.5);
        }

        .ShowMore {
          text-align: right;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .PaginationWrapper {
    display: flex;
    flex-direction: row;
    place-content: center;
    margin-top: 50px;

    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        button {
          border-radius: 5px;
          border: 1px solid #4A4A4A;
          background-color: #4A4A4A;
          color: $text-white;
        }
        &.ant-pagination-disabled {
          button {
            opacity: 0.6;
          }
        }
      }
      .ant-pagination-item {
        background-color: transparent;
        border: unset;
        border-radius: 5px;
        color: $text-white;
        @include pt15;

        a {
          color: $text-white;
        }

        &:hover {
          a {
            color: $text-white;
          }
        }
        &:focus {
          a {
            color: $text-white;
          }
        }

        &.ant-pagination-item-active {
          background-color: $text-white;
          color: $main-color;

          a {
            color: $main-color;
          }
        }
      }
      .ant-pagination-jump-next, .ant-pagination-jump-prev {
        .ant-pagination-item-container {
          .ant-pagination-item-link-icon {
            color: $text-white;
          }

          .ant-pagination-item-ellipsis {
            color: $text-white;
          }
        }
      }
    }
  }
}

.ResultModal {
  .ant-modal-content {
    .ant-modal-close-x {
      width: 36px;
      height: 36px;
      line-height: 36px;
    }
    .ant-modal-body {
      padding: 36px 24px 24px;
    }
  }
  .ModalDesc {
    white-space: pre;
  }
  .CarouselDots {
    top: -10px !important;
    > li {
      > button {
        opacity: 1 !important;
        background: #4A4A4A !important;
      }

      &.slick-active {
        > button {
          background: red !important;
        }
      }
    }
  }
}