@import '../../styles/main.scss';

.ExcursionBlock {
  margin: 50px auto;
  width: 90%;
  max-width: 1200px;

  display: flex;
  flex-direction: column;

  @media(max-width: $desktop-width) {
    margin: 24px auto;
  }

  @media(max-width: $tablet-width) {
    margin: 24px auto;
    width: 100%;
  }

  @media(max-width: $mobile-width) {
    margin: 12px auto 0;
  }

  .Title {
    @include pt30;
    text-transform: uppercase;
    color: $text-black;
    text-align: center;

    @media(max-width: $tablet-width) {
      @include pt20;
    }

    @media(max-width: $mobile-width) {
      @include pt14;
    }
  }

  .MapsWrapper {
    display: flex;
    flex-direction: row;
    place-content: space-between;
    margin-top: 50px;

    @media(max-width: $tablet-width) {
      margin: 0 auto;
      flex-direction: column;
      max-width: 720px;
    }

    @media(max-width: $mobile-width) {
      margin: 0 auto;
      flex-direction: column;
      max-width: 100%;
    }
  }

  .ExcursionRow {
    width: 720px;
    height: 550px;

    > div > div > .ggskin.ggskin_map {
      transform: scale(0.5);
      top: -50px !important;
      left: -90px !important;
    }

    @media(max-width: $tablet-width) {
      margin-top: 32px;
    }

    @media(max-width: $mobile-width) {
      margin-top: 16px;
      width: calc(100vw);
      height: 240px;

      > div > div > .ggskin.ggskin_map {
        transform: scale(0.3);
        top: -80px !important;
        left: -140px !important;
      }
    }
  }

  .YMapRow {
    width: 420px;
    height: 550px;

    margin: 0 0 0 32px;

    @media(max-width: $tablet-width) {
      margin: 18px 0 0;
      width: 100%;
      height: 300px;
    }

    @media(max-width: $mobile-width) {
      height: 200px;
    }
  }
}