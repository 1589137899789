//colors
$main-backgrond: #1E1E1E;
$main-color: #AA2656;
$text-white: #fff;
$text-black: #000;
$text-black-secondary: #414141;
$calendar-white: #E7E7E7;
$calendar-gray: #4A4A4A;
$text-calendar-white: #323232;
$text-calendar-gray: #878787;
// breakpoints
$desktop-width: 1024px;
$tablet-width: 768px;
$mobile-width: 480px;
//font-size
@mixin bold {
  font-weight: 700;
}
@mixin medium {
  font-weight: 500;
}
@mixin regular {
  font-weight: 400;
}
@mixin light {
  font-weight: 300;
}
//text
@mixin pt50 {
  @include bold;
  font-size: 50px;
}
@mixin pt30 {
  @include medium;
  font-size: 30px;
}
@mixin pt25 {
  @include medium;
  font-size: 25px;
}
@mixin pt20 {
  @include medium;
  font-size: 20px;
}
@mixin phoneLink {
  @include bold;
  font-size: 18px;
}
@mixin pt17 {
  @include light;
  font-size: 17px;
}
@mixin pt15 {
  @include regular;
  font-size: 15px;
}

@mixin pt14 {
  @include medium;
  font-size: 14px;
}
@mixin pt12 {
  @include medium;
  font-size: 12px;
}
@mixin pt11 {
  @include medium;
  font-size: 11px;
}
@mixin pt10 {
  @include regular;
  font-size: 10px;
}
@mixin pt9 {
  @include regular;
  font-size: 9px;
}