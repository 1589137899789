@import '../../styles/main.scss';

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  place-content: space-between;
  padding: 0.5em 5em;
  background-color: $main-backgrond;
  color: $text-white;

  @media (max-width: $tablet-width) {
    padding: 0.5em 2em;
  }
}

.HeaderImage {
  @media (max-width: $desktop-width) {
    max-width: 200px;
  }

  @media (max-width: $tablet-width) {
    max-width: 160px;
  }

  @media (max-width: $mobile-width) {
    max-width: 110px;
  }
}

.HeaderItems {
  display: flex;
  flex-direction: row;

  @media (max-width: $tablet-width) {
    flex-wrap: wrap;
  }

  @media (max-width: $mobile-width) {
    display: none;
  }
}

.HeaderItem {
  cursor: pointer;
  @include pt15;
  &:not(:first-child) {
    margin-left: 55px;
  }

  @media (max-width: $tablet-width) {
    &:not(:first-child) {
      margin-left: 30px;
    }
  }
}

.MobileMenu {
  display: none;

  @media (max-width: $mobile-width) {
    display: block;
  }

  .MenuIcon {
    font-size: 20px;
  }
}

.HeaderMenu {
  .HeaderItem {
    color: $text-white;
  }

  .Icon {
    cursor: pointer;
    max-width: 16px;

    &:not(:first-child) {
      margin-left: 32px;
    }
  }
}