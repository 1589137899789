@import '../../styles/main.scss';

.FooterBlock {
  background-color: $main-backgrond;
  padding: 40px 100px;

  @media(max-width: $mobile-width) {
    padding: 30px 12px 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    background-color: #2E2E2E;
  }

  .FooterText {
    @include pt25;
    color: $text-white;

    &:not(:first-child) {
      margin-top: 24px;
    }

    &.Email {
      text-decoration: underline;
    }

    @media(max-width: $mobile-width) {
      @include pt12;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }

  .IconsRow {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    
    .Icon {
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 32px;
      }

      @media(max-width: $mobile-width) {
        width: 16px;
      }
    }

    @media(max-width: $mobile-width) {
      margin-top: 16px;

      .Icon {
        width: 16px;
      }
    }
  }

  .Description {
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    @media(max-width: $mobile-width) {
      margin-top: 8px;
      text-align: left;
    }

    span {
      @include pt17;
      color: $text-white;

      @media(max-width: $mobile-width) {
        font-size: 5px;
        font-weight: 300;
      }
    }
  }
}